require('jquery-ui/ui/widget');
require('jquery-ui/ui/position');
require('jquery-ui/ui/data');
require('jquery-ui/ui/disable-selection');
require('jquery-ui/ui/focusable');
require('jquery-ui/ui/form-reset-mixin');
require('jquery-ui/ui/keycode');
require('jquery-ui/ui/labels');
require('jquery-ui/ui/scroll-parent');
require('jquery-ui/ui/tabbable');
require('jquery-ui/ui/unique-id');
require('jquery-ui/ui/widgets/draggable');
require('jquery-ui/ui/widgets/resizable');
require('jquery-ui/ui/widgets/button');
require('jquery-ui/ui/widgets/checkboxradio');
require('jquery-ui/ui/widgets/controlgroup');
require('jquery-ui/ui/widgets/dialog');
require('jquery-ui/ui/widgets/mouse');
require('jquery-ui/themes/base/core.css');
require('jquery-ui/themes/base/draggable.css');
require('jquery-ui/themes/base/resizable.css');
require('jquery-ui/themes/base/button.css');
require('jquery-ui/themes/base/checkboxradio.css');
require('jquery-ui/themes/base/controlgroup.css');
require('jquery-ui/themes/base/dialog.css');